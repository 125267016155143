<script setup lang="ts">
/* eslint-disable vue/require-default-prop */
const props = withDefaults(
  defineProps<{
    src: string;
    format?: string;
    quality?: string | number;
    background?: string;
    fit?: string;
    modifiers?: Record<string, unknown>;
    preset?: string;
    provider?: string;
    sizes?: string;
    preload?: boolean;
    width?: string | number;
    height?: string | number;
    alt?: string;
    referrerpolicy?: string;
    crossorigin?: 'anonymous' | 'use-credentials' | '';
    loading?: string;
    decoding?: 'async' | 'auto' | 'sync';
  }>(),
  {
    quality: 80,
    provider: 'imageSharp',
    loading: 'lazy'
  }
);
let internalSizes: { [key: string]: string } = {};

if (props.sizes) {
  internalSizes = {};
  const screens = useNuxtApp().$img.options.screens;
  if (typeof props.sizes === 'string') {
    for (const entry of props.sizes.split(/[\s,]+/).filter((e) => e)) {
      const s = entry.split(':');
      if (s.length !== 2) {
        continue;
      }
      internalSizes[s[0].trim()] = s[1].trim();
    }
  } else {
    Object.assign(internalSizes, props.sizes);
  }

  const maxSizeIndex = Object.keys(internalSizes).reduce((maxIndex, key) => {
    const index = Object.keys(screens).indexOf(key);

    if (index > maxIndex) {
      maxIndex = index;
    }

    return maxIndex;
  }, 0);

  Object.keys(screens).forEach((key, index) => {
    if (index < maxSizeIndex && !internalSizes[key]) {
      internalSizes[key] = '100vw';
    }
  });
}

const defaultProps = reactive({
  ...props,
  internalSizes
});
</script>

<template>
  <NuxtImg v-bind="defaultProps" />
</template>
